import { apiRequest } from '../../common/request';

interface ICreateInvoice {
  partner: string;
  hash: string;
  paymentMethod: 'paypal';
  paymentEmail: string;
  amount: number;
}
export const createInvoice = async (data: ICreateInvoice) => {
  const pathname = '/invoice';

  const [err, responseBody] = await apiRequest.post(pathname, data);

  if (err) {
    return [false, null];
  }

  if (responseBody.statusCode === 403) {
    return [false, 'invalid_hash'];
  }

  if (responseBody.statusCode === 404) {
    return [false, 'project_not_found'];
  }

  return [true, responseBody];
}
