import React, { FC, memo, useState } from 'react';

import SectionTitle from './SectionTitle';
import { Button, Card, message, Modal, Row } from 'antd';
import Invoice from './Invoice';
import WithdrawalForm from './WithdrawalForm';
import { usePartner } from '../../hooks/usePartner';
import ReferralLink from './ReferralLink';
import { createInvoice } from './api';
import { useParams } from 'react-router-dom';

const renderBalance = (amount: number) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  }).format(amount / 100)
}

const renderPercent = (percents: number) => {
  return new Intl.NumberFormat('en-US', {
    style: 'percent',
  }).format(percents / 100);
}

const Dashboard: FC = () => {
  const [isWithdrawModalOpened, setIsWithdrawModalOpened] = useState(false);
  const [isWithdrawalBtnLoading, setIsWithdrawalBtnLoading] = useState<boolean>(false);

  const { partner, hash } = useParams();

  const partnerContext = usePartner();

  const openWithdrawModal = () => {
    setIsWithdrawModalOpened(true);
  }

  const closeWithdrawModal = () => {
    setIsWithdrawModalOpened(false);
  }

  const makeInvoice = async (paypalEmail: string, amount: number) => {
    setIsWithdrawalBtnLoading(true);

    if (amount > partnerContext.balance) {
      message.error('Amount is too high');
      setIsWithdrawalBtnLoading(false);
      return;
    }

    const [isRequestSucceed, reason] = await createInvoice({
      partner: partner!,
      hash: hash!,
      paymentMethod: 'paypal',
      paymentEmail: paypalEmail,
      amount,
    });

    setIsWithdrawalBtnLoading(false);
    if (!isRequestSucceed) {
      message.error('Cannot create invoice');
      return;
    }

    setIsWithdrawModalOpened(false);
    message.success('Invoice created successfully');

    partnerContext.dispatch({
      type: 'SET',
      payload: {
        balance: partnerContext.balance - amount,
        invoices: [{
          id: reason._id,
          amount: reason.amount,
          status: 'failed',
          createdAt: new Date(),
          paymentInfo: {
            email: paypalEmail,
            transactionId: '',
          },
        },
          ...partnerContext.invoices,
        ],
      }
    });
  }


  return (
    <>
      <SectionTitle title='Balance' />
      <Row justify='start'>
        <Card className='section_balance__info_card'>
          <span className='section__balance__amount_card_title'>Available to pay out</span>
          <span className='section__balance__amount'>{renderBalance(partnerContext.balance)}</span>
          <Button
            className='section__balance__withdraw_btn'
            type='primary'
            onClick={openWithdrawModal}
            disabled={partnerContext.balance === 0}
          >
            Pay out
          </Button>
        </Card>

        <Card className='section_balance__info_card section_balance__percents_card'>
          <span className='section__balance__amount_card_title'>Your affiliate</span>
          <span className='section__balance__amount'>{renderPercent(partnerContext.refRule?.percent)}</span>
          <span className='section__percents_extra'>for every payment</span>
        </Card>
      </Row>

      <br />
      <br />
      <br />
      <br />
      <br />

      <SectionTitle title='Referral links' />
      <div className='section__links__container'>
        <div>Link</div>
        <div>Clicks</div>
        <div>Payments</div>

        {partnerContext.links.map((link: any) => (
          <ReferralLink
            suffix={link.refLink}
            key={link.id}
            payments={link.payments}
            clicks={link.clicks}
          />
        ))}
      </div>

      <br />
      <br />
      <br />
      <br />
      <br />

      <SectionTitle title='Account history' />
      <div className='section__withdrawal_history__container'>
        <div>Date</div>
        <div>Amount</div>
        <div>Status</div>
        <div>Transaction ID</div>
        {(partnerContext.invoices || []).map((invoice: any) => (
          <Invoice
            transactionId={invoice?.paymentInfo?.transactionId}
            createdAt={invoice.createdAt}
            amount={invoice.amount}
            paid={invoice.status === 'finished'}
            key={invoice._id}
          />
        ))}
      </div>

      <Modal open={isWithdrawModalOpened} onCancel={closeWithdrawModal} title='Withdraw funds' footer={false}>
        <WithdrawalForm isLoading={isWithdrawalBtnLoading} makeInvoice={makeInvoice} maxBalance={partnerContext.balance} />
      </Modal>
    </>
  );
}

export default memo(Dashboard);
