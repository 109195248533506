import React, { FC, memo } from 'react';
import { Button, Form, Input, InputNumber } from 'antd';

interface IWithdrawalForm {
  isLoading: boolean;
  makeInvoice: (email: string, amount: number) => void;
  maxBalance: number;
}
const WithdrawalForm: FC<IWithdrawalForm> = (props) => {
  const [form] = Form.useForm();

  const onFormFinish = async (values: any) => {
    props.makeInvoice(values.email, values.amount * 100);
  }

  const fillMaxAmount = () => {
    form.setFieldValue('amount', props.maxBalance / 100)
  }

  return (
    <Form layout='vertical' form={form} requiredMark={false} onFinish={onFormFinish} autoComplete='off'>
      <Form.Item label='Paypal email:' name='email' rules={[{ required: true, message: 'Please input your PayPal email' }, { type: 'email', message: 'Incorrect email address' }]}>
        <Input placeholder='example@example.com' />
      </Form.Item>
      <Form.Item label='Amount:' name='amount' rules={[{ type: 'number', required: true, min: 50, max: props.maxBalance / 100 }]}>
        <InputNumber style={{ width: '100%' }} addonAfter={<Button onClick={fillMaxAmount} type='link' size='small'>Max: {props.maxBalance / 100}</Button>} />
      </Form.Item>

      <Form.Item>
        <Button htmlType='submit' type='primary' loading={props.isLoading}>Withdraw</Button>
      </Form.Item>
    </Form>
  );
}

export default memo(WithdrawalForm);
