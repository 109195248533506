import React, { FC, PropsWithChildren, memo, useEffect, useCallback } from 'react';
import { Col, Divider, message, Row, Space } from 'antd';

import { ArrowLeftOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';

import { getPartnerInfo } from './api';
import { usePartner } from '../../hooks/usePartner';

import './layout.css';

const MainLayout: FC<PropsWithChildren> = (props) => {
  const { partner, hash } = useParams();

  const partnerContext = usePartner();

  const updatePartnerInformation = useCallback(async () => {
    const [isRequestSucceed, reason] = await getPartnerInfo(partner!, hash!);
    if (!isRequestSucceed) {
      message.error('Cannot load information');
      return;
    }

    partnerContext.dispatch({
      type: 'SET',
      payload: {
        id: reason.publicId,
        email: reason.email,
        registrations: reason.registrations,
        clicks: reason.clickThroughs,
        balance: reason.balance,
        paymentsCount: reason.payments,
        transactionsAmount: reason.transactionsAmount,
        returnUrl: reason.returnUrl,
        projectName: reason.projectName,
        invoices: (reason.invoices || []).map((invoice: any) => ({
          id: invoice._id,
          amount: invoice.amount,
          status: invoice.paid ? 'finished' : 'failed',
          createdAt: invoice.createdAt,
          paymentInfo: invoice.paymentInfo,
        })),
        links: (reason.refLinks || []).map((link: any) => ({
          id: link._id,
          refLink: link.refLink,
          isActive: link.isActive,
          registrations: link.registrations,
          clicks: link.clickThroughs,
          payments: link.payments,
          transactionsAmount: link.transactionsAmount,
          createdAt: link.createdAt,
        })),
        refRule: {
          pendingPeriod: reason.refRule?.pendingPeriod ?? 10,
          percent: reason.refRule?.percent ?? 10,
          fix: reason.refRule?.fix ?? 0,
        }
      }
    });

    // eslint-disable-next-line
  }, [hash, partner]);

  useEffect(() => {
    updatePartnerInformation();
  }, [updatePartnerInformation]);

  return (
    <Row className='layout__container'>
      <Col span={8} className='layout__container__col layout__container__left_side'>
        <div>
          <h1 className='layout__container__left_side__title'>Manage your {partnerContext.projectName} affiliate program</h1>

          <a className='layout__container__left_side__link' href={partnerContext.returnUrl}>
            <ArrowLeftOutlined />
            Return to {partnerContext.projectName}
          </a>
        </div>

        <Space direction='horizontal' align='baseline'>
          <a
            href='https://affilink.io'
            target='_blank'
            className='layout__container__left_side__link layout__container__left_side__affilink_link'
            rel='noreferrer'
          >
            Powered by <img className='layout__container__left_side__logo' src='/inline-logo.svg' alt='' />
          </a>

          <Divider type='vertical' />

          <a
            href='https://affilink.io/privacy'
            target='_blank'
            className='layout__container__left_side__link layout__container__left_side__affilink_link'
            rel='noreferrer'
          >
            Privacy policy
          </a>
        </Space>
      </Col>

      <Col span={16} className='layout__container__col'>
        {props.children}
      </Col>
    </Row>
  );
}

export default memo(MainLayout);
