import React, { FC, memo } from 'react';
import { Button, message } from 'antd';
import { CopyOutlined } from '@ant-design/icons';

interface IReferralLink {
  suffix: string;
  clicks: number;
  payments: number;
}
const ReferralLink: FC<IReferralLink> = (props) => {
  const copyToClipboard = () => {
    window.navigator.clipboard.writeText(`https://r.affilink.io/${props.suffix}`);
    message.info('Copied to clipboard');
  }

  return (
    <>
      <div>
        <span
          className='section__links__container_link'
          onClick={copyToClipboard}
        >
          r.affilink.io/{props.suffix}
        </span> {' '}
        <Button
          icon={<CopyOutlined />}
          type='text'
          onClick={copyToClipboard}
        >
          Copy
        </Button>
      </div>
      <div>{props.clicks}</div>
      <div>{props.payments}</div>
    </>
  );
}

export default memo(ReferralLink);
