import React, { FC, memo } from 'react';

interface ITag {
  status: 'success' | 'waiting';
}
const Tag: FC<ITag> = (props) => {
  const className = `section__withdrawal_history__tag ${props.status === 'success' ? 'section__withdrawal_history__tag_positive' : 'section__withdrawal_history__tag_default'}`;

  return (
    <div className={className}>
      {props.status === 'success' ? 'Paid' : 'Open'}
    </div>
  );
}

export default memo(Tag);
