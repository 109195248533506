import React, { FC, memo, useMemo } from 'react';
import Tag from './Tag';

interface IInvoice {
  createdAt: Date;
  paid: boolean;
  amount: number;
  transactionId?: string;
}
const Invoice: FC<IInvoice> = (props) => {
  const currentDate = useMemo(() => new Intl.DateTimeFormat('en-US', {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
  }).format(new Date(props.createdAt)), [props.createdAt]);

  const amount = useMemo(() => new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(props.amount / 100), [props.amount]);

  return (
    <>
      <div>{currentDate}</div>
      <div>{amount}</div>
      <Tag status={props.paid ? 'success' : 'waiting'} />
      <div>{props.transactionId}</div>
    </>
  );
}

export default memo(Invoice);
