import React, { FC, memo } from 'react';
import MainLayout from '../Components/Layout';
import Dashboard from '../Components/Dashboard';
import { PartnerContextProvider } from '../contexts/partnerContext';

const MainPage: FC = () => {
  return (
    <PartnerContextProvider>
      <MainLayout>
        <Dashboard />
      </MainLayout>
    </PartnerContextProvider>
  );
}

export default memo(MainPage);
