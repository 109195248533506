import { createBrowserRouter } from 'react-router-dom';
import MainPage from './Pages/MainPage';

export const router = createBrowserRouter([
  {
    path: '/:partner/:hash',
    element: <MainPage />,
  },
  {
    path: '*',
    element: <div>Session expired</div>
  },
]);
