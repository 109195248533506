import React, { FC, memo } from 'react';
import { Divider } from 'antd';

import './sectionTitle.css';

interface ISectionTitle {
  title: string;
}
const SectionTitle: FC<ISectionTitle> = (props) => {
  return (
    <div className='section_title__container'>
      <span className='section_title__title'>{props.title}</span>
      <Divider className='section_title__container__divider' />
    </div>
  );
}

export default memo(SectionTitle);
