import { createContext, FC, PropsWithChildren, useReducer } from 'react';
import { IPartner } from './interfaces';
import { IPartnerAction } from './interfaces/IPartnerAction';

export const PartnerContext = createContext<any>(null);

export const partnerReducer = (currentPartner: IPartner, action: IPartnerAction) => {
  switch (action.type) {
    case 'SET':
      return { ...currentPartner, ...action.payload };
    default:
      return currentPartner;
  }
}

export const PartnerContextProvider: FC<PropsWithChildren> = (props) => {
  const [partner, dispatch] = useReducer(partnerReducer, {
    id: '',
    email: '',
    balance: 0,
    returnUrl: '',
    projectName: '',
    affiliateFix: 0,
    affiliatePercents: 0,
    invoices: [],
    paymentsCount: 0,
    registrations: 0,
    transactionsAmount: 0,
    clicks: 0,
    links: [],
    refRule: {
      pendingPeriod: 0,
      percent: 10,
      fix: 0,
    },
  });

  return (
    <PartnerContext.Provider value={{ ...partner, dispatch }}>
      {props.children}
    </PartnerContext.Provider>
  )
}
