import { apiRequest } from '../../common/request';

export const getPartnerInfo = async (partner: string, hash: string) => {
  const pathname = `/partner/${partner}/${hash}`

  const [err, responseBody] = await apiRequest.get(pathname);
  if (err) {
    return [false, null];
  }

  if (responseBody.statusCode === 403) {
    return [false, 'invalid_hash'];
  }

  if (responseBody.statusCode === 404) {
    return [false, 'project_not_found'];
  }

  return [true, responseBody];
}
